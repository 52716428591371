import { FC, useState } from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import { isEmpty } from "lodash";

import TextField from "./Client/TextField";
import PhoneInput from "./Form/PhoneInput";
import SingleChoiceField from "./IntakeForm/Fields/SingleChoiceField";

export const Label = ({ children }) => (
  <p className="font-medium text-foreground">{children}</p>
);

interface FieldRowProps {
  label: string;
  children?: React.ReactNode;
  className?: string;
}

export const FieldRow: FC<FieldRowProps> = ({
  label,
  children,
  className = "",
}) => (
  <div className={classNames("mb-8", className)}>
    <Label>{label}</Label>
    {children}
  </div>
);

export const CommunicationField = ({
  errors,
  control,
  withReminder,
  withOutgoingCall,
  communication,
}) => {
  const showCommunication = !isEmpty(communication) && communication.length > 1;

  const isFirstOptionOutgoingCall =
    !isEmpty(communication) && communication[0]?.value === "outgoing-call";
  const [enableOutgoingCallPhoneInput, setEnableOutgoingCallPhoneInput] =
    useState(isFirstOptionOutgoingCall);

  const getCommunicationLabel = (item: any) => {
    const labels = {
      "google-call": "Google meet",
      "zoom-call": "Zoom",
      "video-call": "Custom video call",
      "in-person": `In-person: ${item?.extra}`,
      "voice-call": "I will call you",
      "outgoing-call": "You will call me",
      "skype-consumer-call": "Skype",
      "skype-business-call": "Skype",
      "teams-call": "Teams",
    };

    return labels[item.value];
  };

  const renderPhone = (
    <FieldRow label="What’s your phone number? *">
      <PhoneInput
        control={control}
        name="phone"
        placeholder="Phone number"
        errors={errors}
        infoText={
          withReminder && "I will send you a reminder before our appointment"
        }
        international
        required={true}
        errorMessage="A valid phone number is required"
      />
    </FieldRow>
  );

  return (
    <>
      {withReminder && renderPhone}
      {showCommunication ? (
        <FieldRow label="What’s your preferred communication channel? *">
          <Controller
            control={control}
            name="communication"
            defaultValue={communication[0]}
            render={({ field }) => (
              <div
                className="mt-2 space-y-8"
                id={`communication-option-${field.value?.value}`}
              >
                <SingleChoiceField
                  value={field.value.value}
                  onChange={(val) => {
                    setEnableOutgoingCallPhoneInput(val === "outgoing-call");
                    field.onChange(
                      communication.find((item) => item.value === val)
                    );
                  }}
                  item={{
                    options: communication.map((item) => ({
                      ...item,
                      label: getCommunicationLabel(item),
                    })),
                  }}
                />
                {enableOutgoingCallPhoneInput &&
                  withOutgoingCall &&
                  !withReminder &&
                  renderPhone}
              </div>
            )}
          />
        </FieldRow>
      ) : null}
    </>
  );
};

export const ContactInfoForm = ({
  register,
  errors,
  onBlurEmail,
  stackNames = false,
}) => {
  return (
    <>
      <p className="font-sm mb-8 text-foreground/50">* Required information</p>
      <div className={classNames(!stackNames && "sm:flex", "w-full gap-2")}>
        <FieldRow className="w-full" label="First name *">
          <TextField
            id="firstName"
            type="text"
            containerClassName="mb-2"
            placeholder="First name"
            required={true}
            register={register}
            errors={errors}
            errorMessage="First name is required"
          />
        </FieldRow>
        <FieldRow className="w-full" label="Last name *">
          <TextField
            id="lastName"
            type="text"
            containerClassName=""
            placeholder="Last name"
            register={register}
            errors={errors}
          />
        </FieldRow>
      </div>
      <FieldRow label="What’s your email? *">
        <TextField
          id="email"
          type="email"
          containerClassName=""
          placeholder="Email"
          required={true}
          register={register}
          errors={errors}
          isEmailErrorMessage="Email should be a valid email address"
          isEmail
          errorMessage="Email is required"
          onBlur={onBlurEmail}
        />
      </FieldRow>
    </>
  );
};
